import {MutableRefObject} from 'react';
import {useTheme, Typography, Box, Grid, Divider} from '@mui/material';

import {StyledContainer} from './styles';
import {DynamicData, StaticData} from '../../../api';
import moment from 'moment';
import {TreatmentSection} from '../../../sections/participant/treatment';

interface ParticipantDetailsSectionProps {
  participantDetailsRef: MutableRefObject<HTMLDivElement | null>;
  staticData: StaticData;
  dynamicData: DynamicData;
}

export const ParticipantDetailsSection = ({
  participantDetailsRef,
  staticData,
  dynamicData,
}: ParticipantDetailsSectionProps): JSX.Element => {
  const {cgmMeasurements, devices} = dynamicData;

  const {
    birthDate,
    clinicalTrial,
    diabetesFoundDate,
    gender,
    heightInCm,
    therapyStartDate,
    weightInKg,
    totalDailyInsulin,
    bmi,
    a1c,
  } = staticData;

  const theme = useTheme();

  const changeFormateDate = (oldDate: string) => {
    return moment(oldDate, 'YYYY/MM/DD').format('DD/MM/YYYY');
  };
  const isDexcomG6 = devices?.filter(device => device.model == 'Dexcom G6').length > 0;

  const deviceTypeMap: {[key: string]: string} = {
    PUMP: 'Insulin pump',
    PHONE: 'Phone',
    CGM: 'CGM',
  };

  return (
    <StyledContainer ref={participantDetailsRef}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <TreatmentSection staticData={staticData} cgmMeasurements={cgmMeasurements} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={{minHeight: '662px', boxShadow: 1, borderRadius: 2, padding: 2, background: theme.palette.basic[0]}}>
            <Typography variant="h3" color={theme.palette.basic[1000]}>
              Demographics
            </Typography>
            <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
              Personal information
            </Typography>
            <Grid container spacing={2} mt={0.5} mb={1}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Participant ID
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {clinicalTrial.id}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Time zone
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  Europe/Zurich
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5} mb={1}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Sex
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {gender}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Age
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {moment().diff(birthDate, 'years')} years
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5} mb={4}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Height
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {heightInCm} cm
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Weight
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {weightInKg} kg
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5} mb={4}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  BMI
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {bmi} kg/m²
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  null
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  null
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={4}>
              Information about diabetes
            </Typography>
            <Grid container spacing={2} mt={0.5} mb={4}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Last HbA1c (%)
                </Typography>
                <Typography variant="h3" color={theme.palette.basic[1000]}>
                  {a1c}%
                </Typography>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  TDI
                </Typography>
                <Typography variant="h3" color={theme.palette.basic[1000]}>
                  {totalDailyInsulin} U/day
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} mt={0.5} mb={4}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Date of diagnosis
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {changeFormateDate(diabetesFoundDate)}
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Date of insulin therapy start
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  {changeFormateDate(therapyStartDate)}
                </Typography>
              </Grid>
            </Grid>
            <Divider />
            <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={4}>
              Study center information
            </Typography>
            <Grid container spacing={2} mt={0.5} mb={2}>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Center
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  null
                </Typography>
              </Grid>
              <Grid item xs={12} lg={6}>
                <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                  Protocol acronym
                </Typography>
                <Typography variant="h4" color={theme.palette.basic[1000]}>
                  null
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Box sx={{minHeight: '662px', boxShadow: 1, borderRadius: 2, padding: 2, background: theme.palette.basic[0]}}>
            <Typography variant="h3" color={theme.palette.basic[1000]}>
              Devices
            </Typography>
            {devices?.map(device => {
              return (
                <Grid container item xs={12} key={device.deviceType + device.id}>
                  <Grid item xs={12}>
                    <Typography variant="h4" color={theme.palette.basic[1000]} marginTop={3}>
                      {deviceTypeMap[device?.deviceType]}
                    </Typography>
                  </Grid>
                  <Grid container spacing={2} mt={0.5} mb={1}>
                    <Grid item xs={12} lg={6} mb={1}>
                      <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                        Model
                      </Typography>
                      <Typography variant="h4" color={theme.palette.basic[1000]}>
                        {device.model}
                      </Typography>
                    </Grid>
                    {!(isDexcomG6 && device.deviceType == 'CGM') && (
                      <Grid item xs={12} lg={6} mb={1}>
                        <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                          Serial number
                        </Typography>
                        <Typography variant="h4" color={theme.palette.basic[1000]}>
                          {device.serialNumber}
                        </Typography>
                      </Grid>
                    )}
                    {!(isDexcomG6 && device.deviceType == 'CGM') && (
                      <Grid item xs={12} lg={6} mb={1}>
                        <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                          Alias
                        </Typography>
                        <Typography variant="h4" color={theme.palette.basic[1000]}>
                          {device.alias}
                        </Typography>
                      </Grid>
                    )}
                    {isDexcomG6 && device.deviceType == 'CGM' && (
                      <Grid item xs={12} lg={6} mb={1}>
                        <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                          Transmitter Serial number
                        </Typography>
                        <Typography variant="h4" color={theme.palette.basic[1000]}>
                          null
                        </Typography>
                      </Grid>
                    )}
                    {isDexcomG6 && device.deviceType == 'CGM' && (
                      <Grid item xs={12} lg={6} mb={1}>
                        <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                          Sensor Code
                        </Typography>
                        <Typography variant="h4" color={theme.palette.basic[1000]}>
                          null
                        </Typography>
                      </Grid>
                    )}
                    {isDexcomG6 && device.deviceType == 'CGM' && (
                      <Grid item xs={12} lg={6} mb={1}>
                        <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                          Operating time
                        </Typography>
                        <Typography variant="h4" color={theme.palette.basic[1000]}>
                          null
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={12} lg={6} mb={1}>
                      <Typography variant="body1" color={theme.palette.customColors?.lightGrey}>
                        Last updated
                      </Typography>
                      <Typography variant="h4" color={theme.palette.basic[1000]}>
                        {changeFormateDate(device.lastUpdatedTimestamp)}
                      </Typography>
                    </Grid>
                  </Grid>
                  {(device.deviceType == 'PHONE' || device.deviceType == 'PUMP') && (
                    <Grid item xs={12} lg={6} mb={1} paddingTop={'1rem'}>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </StyledContainer>
  );
};
