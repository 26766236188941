import {Typography, TypographyProps, useTheme} from '@mui/material';

import {setNextUnit} from '../../store/reducers/unitSlice';
import {useAppDispatch} from '../../store';
import type {Unit} from '../../api/service-types';

import {StyledContainer, StyledSwitch} from './styles';
import {useState} from 'react';

export const SwitchUnit = (): JSX.Element => {
  const theme = useTheme();

  const dispatch = useAppDispatch();
  const [storageNextUnit, setStorageNextUnit] = useState(localStorage.getItem('storageNextUnit') || 'mg/dL');

  const onChangeUnit = (nextUnit: Unit) => {
    localStorage.setItem('storageNextUnit', nextUnit);
    const storageUnit = localStorage.getItem('storageNextUnit');
    storageUnit && setStorageNextUnit(storageUnit);

    dispatch(setNextUnit(nextUnit));
  };

  const getTypographyProps = (isSelected: boolean): TypographyProps => {
    return {
      color: isSelected ? theme.palette.basic[0] : theme.palette.basic[800],
      variant: isSelected ? 'bodyBigSemibold' : 'bodyBig',
    };
  };

  const isMgSelected = storageNextUnit === 'mg/dL';
  const isMmolSelected = storageNextUnit === 'mmol/L';

  return (
    <StyledContainer>
      <StyledSwitch
        onClick={() => {
          onChangeUnit('mg/dL');
        }}
        isSelected={isMgSelected}
        variant="left">
        <Typography {...getTypographyProps(isMgSelected)}>mg/dL</Typography>
      </StyledSwitch>
      <StyledSwitch
        onClick={() => {
          onChangeUnit('mmol/L');
        }}
        isSelected={isMmolSelected}
        variant="right">
        <Typography {...getTypographyProps(isMmolSelected)}>mmol/L</Typography>
      </StyledSwitch>
    </StyledContainer>
  );
};
