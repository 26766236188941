import React, {useEffect, useState} from 'react';
import {Popover, Tooltip, Typography, useTheme} from '@mui/material';

import {Avatar, Icon, Search, CircularProgress, SnackbarAlert} from '../../components';
import {getFullName, getInitials} from '../../helpers';
import {usePopover, useSearch} from '../../hooks';
import {userService, eventsService} from '../../api';

import {Severity} from '../event-comments/types';
import {StyledAssignButton, StyledMenuItem, StyledParticipant, StyledPopoverContent} from './styles';

type AssignUserProps = {
  event: any;
  setSelectedEvent: React.Dispatch<React.SetStateAction<string>>;
  onRefetch: () => void;
  onClose: () => void;
};

export const AssignUser = ({event, onRefetch, setSelectedEvent}: AssignUserProps): JSX.Element => {
  const theme = useTheme();

  const {open, handleOpen, handleClose} = usePopover<HTMLButtonElement>();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [assignedUserId, setAssignedUserId] = useState<number | null>(event?.assignedUserId ?? null);
  const [severity, setSeverity] = useState<Severity>('success');
  const [message, setMessage] = useState('');
  const {searchValue, onChangeSearchValue, onResetSearchValue} = useSearch();

  const [updateEvent] = eventsService.useUpdateEventMutation();

  const assignUser = async (nextAssignedUserId: number | null) => {
    try {
      await updateEvent({
        body: {
          id: event.id,
          assignedUserId: nextAssignedUserId,
        },
      });
      setSelectedEvent(() => ({
        ...event,
        assignedUserId: nextAssignedUserId,
      }));
      setMessage('User assigned successfully!');
      setSeverity('success');
      setOpenSnackbar(true);
      handleClose();
      setAssignedUserId(nextAssignedUserId);
      onRefetch();
    } catch (error) {
      setMessage('Error while assigning user!');
      setSeverity('error');
      setOpenSnackbar(true);
      handleClose();
      console.error('ERROR - assignUser', error);
    }
  };

  const {
    data: users,
    isLoading: areUsersLoading,
    error: usersError,
    refetch: refetchUsers,
  } = userService.useGetUsersQuery();

  const onRefetchUsers = () => {
    refetchUsers();
  };

  if (areUsersLoading) {
    return <CircularProgress size={40} />;
  }

  if (usersError) {
    return (
      <div>
        <h1>Error</h1>
        <button onClick={onRefetchUsers}>Reload data</button>
      </div>
    );
  }

  if (!users) {
    console.error('users are undefined, unhandled state');
    return <CircularProgress size={40} />;
  }

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') return;
    setOpenSnackbar(false);
  };

  const filteredUsers = users.filter(user => {
    const fullName = getFullName({firstName: user.firstName, lastName: user.lastName}).toLowerCase();
    return fullName.includes(searchValue.toLowerCase());
  });

  const assignedUser = users.find(user => user.id === assignedUserId);

  useEffect(() => {
    setAssignedUserId(event.assignedUserId);
  }, [event.assignedUserId]);

  return (
    <>
      <Tooltip
        title={
          assignedUser?.firstName
            ? assignedUser?.firstName + ' ' + assignedUser?.lastName
            : 'Assign a user to this event.'
        }
        arrow>
        <StyledAssignButton onClick={handleOpen} open={Boolean(open)}>
          {assignedUser ? (
            <div>
              <Avatar
                initials={getInitials({
                  firstName: assignedUser.firstName,
                  lastName: assignedUser.lastName,
                })}
                size="sm"
                typographyVariant="uppercaseSmall"
                src={null} // TODO: Replace when back-end provides user avatars.
              />
            </div>
          ) : (
            <Icon icon="add-user" size="16px" />
          )}
        </StyledAssignButton>
      </Tooltip>

      <Popover
        sx={{zIndex: 9999}}
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        transformOrigin={{vertical: 'top', horizontal: 'center'}}>
        <StyledPopoverContent>
          <Search value={searchValue} onChange={onChangeSearchValue} onReset={onResetSearchValue} />
          <div>
            {filteredUsers.map(user => {
              const isUserAssigned = user.id === assignedUserId;
              return (
                <StyledMenuItem key={user.id} onClick={() => assignUser(isUserAssigned ? null : user.id)}>
                  <StyledParticipant isSelected={isUserAssigned}>
                    <Avatar
                      initials={getInitials({
                        firstName: user.firstName,
                        lastName: user.lastName,
                      })}
                      size="xs"
                      typographyVariant="uppercaseSmall"
                      src={null} // TODO: Replace when back-end provides user avatars.
                    />
                    <Typography
                      variant={isUserAssigned ? 'bodyBigSemibold' : 'bodyBig'}
                      sx={{whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                      {getFullName({
                        firstName: user.firstName,
                        lastName: user.lastName,
                      })}
                    </Typography>
                  </StyledParticipant>
                  {isUserAssigned && <Icon icon="check" size="14px" color={theme.palette.primaryCustom[800]} />}
                </StyledMenuItem>
              );
            })}
          </div>
        </StyledPopoverContent>
      </Popover>
      <SnackbarAlert open={openSnackbar} onClose={handleSnackbarClose} message={message} severity={severity} />
    </>
  );
};
