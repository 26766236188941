import moment from 'moment';
import {timeZone} from '../../utils';

export const timeSampConvert = (timeSamp: string) => {
  return moment.tz(timeSamp, timeZone).format('YYYY-MM-DDTHH:mm:ss');
};

// get micro bolus (MB) and automatic correction bolus (ACB) from total total injected insulin (TD)
export const calculateInsuline = (
  injectedValue: number | null,
  basalSafetyNetAmount: number | null,
  correctionBolusSafetyNetAmount: number | null,
  loopMode: string,
): {
  mb: number | null;
  acb: number | null;
} => {
  const validInjectedValue = injectedValue ?? 0;
  let validBasalSafetyNetAmount;
  let validCorrectionBolusSafetyNetAmount;

  if (validInjectedValue === 0) {
    validBasalSafetyNetAmount = 0;
    validCorrectionBolusSafetyNetAmount = 0;
  } else {
    validBasalSafetyNetAmount = basalSafetyNetAmount ?? 0;
    validCorrectionBolusSafetyNetAmount = correctionBolusSafetyNetAmount ?? 0;
  }

  // case 1: TD = 0, MB = 0, ACB = 0
  if (
    loopMode === 'CLOSED_LOOP' &&
    validInjectedValue === 0 &&
    validBasalSafetyNetAmount === 0 &&
    validCorrectionBolusSafetyNetAmount === 0
  ) {
    return {mb: 0, acb: 0};
  }

  // case 2: TD > 0
  if (validInjectedValue > 0 && loopMode == 'CLOSED_LOOP') {
    // MB = 0, ACB > 0 -> ACB = TD
    if (validBasalSafetyNetAmount === 0 && validCorrectionBolusSafetyNetAmount > 0) {
      return {mb: 0, acb: injectedValue};
    }
    // MB > 0, ACB = 0 -> MB = TD
    if (validBasalSafetyNetAmount > 0 && validCorrectionBolusSafetyNetAmount === 0) {
      return {mb: injectedValue, acb: 0};
    }
    // MB > 0, ACB > 0 -> MB = TD - ACB_safetyNetAmount, ACB = ACB_safetyNetAmount
    if (validBasalSafetyNetAmount > 0 && validCorrectionBolusSafetyNetAmount > 0) {
      return {
        mb: validInjectedValue - validCorrectionBolusSafetyNetAmount,
        acb: validCorrectionBolusSafetyNetAmount,
      };
    }
  }

  // null in case no valid calculation possible
  return {mb: null, acb: null};
};
