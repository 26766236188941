import {Container, Typography} from '@mui/material';

import {LoginForm} from '../../../sections';

import {StyledContent} from './styles';

export const LoginPage = () => {
  return (
    <Container maxWidth="sm">
      <StyledContent>
        <Typography variant="h1" gutterBottom>
          Login
        </Typography>

        <LoginForm />
      </StyledContent>
    </Container>
  );
};
