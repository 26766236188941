import {useState, useEffect, useRef} from 'react';
import {useParams} from 'react-router-dom';
import {CircularProgress} from '../../components';
import {GeneralSection, ParticipantDetailsSection} from '../../sections';
import {participantsService, StaticData, DynamicData} from '../../api';
import {StyledContainer} from './styles';

export const ParticipantPage = () => {
  const generalRef = useRef<HTMLDivElement>(null);
  const participantDetailsRef = useRef<HTMLDivElement>(null);
  const {id} = useParams();

  const [initiallyRendered, setInitiallyRendered] = useState<boolean>(false);
  const [staticData, setStaticData] = useState<StaticData>();
  const [dynamicData, setDynamicData] = useState<DynamicData>();

  const {
    data: overallScreenData,
    isLoading: isOverallScreenDataLoading,
    error: overallScreenDataError,
    refetch: refetchOverallScreenData,
  } = participantsService.useGetOverallScreenDataQuery({
    params: {id: id},
  });

  const onRefetch = async () => {
    refetchOverallScreenData();
  };

  useEffect(() => {
    setStaticData(overallScreenData?.participant);
    setDynamicData(overallScreenData?.dynamicData);
    setInitiallyRendered(true);
  }, [overallScreenData]);

  useEffect(() => {
    if (initiallyRendered) {
      const interval = setInterval(() => {
        //refetchDynamicScreenData();
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [initiallyRendered]);

  if (isOverallScreenDataLoading) {
    return (
      <div style={{width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} />
      </div>
    );
  }

  // TODO: This is a temporary solution. We have to discuss and choose some solution
  if (overallScreenDataError) {
    return (
      <div
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <h1>Error</h1>
        <button onClick={onRefetch}>Reload data</button>
      </div>
    );
  }

  if (!overallScreenData) {
    throw new Error('Overall screen data is undefined, unhandled state');
  }

  return (
    <>
      <StyledContainer>
        {staticData && dynamicData && (
          <GeneralSection generalRef={generalRef} staticData={staticData} dynamicData={dynamicData} />
        )}
        {staticData && dynamicData && (
          <ParticipantDetailsSection
            participantDetailsRef={participantDetailsRef}
            staticData={staticData}
            dynamicData={dynamicData}
          />
        )}
        {/* {staticData && (
          <AdvancedReadingSection advancedReadingRef={advancedReadingRef} participant={staticData?.participant} />
        )} */}
      </StyledContainer>
    </>
  );
};
