import {Box, Typography} from '@mui/material';
import {ReactElement, JSXElementConstructor, ReactNode, ReactPortal} from 'react';

export const eventTypes = [
  'App Event',
  'Application Control Mode Change Event',
  'Basal Profile Change Event',
  'CGM Event',
  'Controller Event',
  'Exercise Event',
  'Insulin Computed Event',
  'Insulin Standard Bolus',
  'Insulin Extended Bolus',
  'Insulin Dual Bolus',
  'Insulin Pump Event',
  'Meal Event',
  'Note Event',
  'Phone Event',
  'Temporal Basal Change Event',
];

export const eventParameterMap = new Map([
  ['message', 'Message:'],
  ['exception', 'Exception:'],
  ['fromMode', 'From mode:'],
  ['toMode', 'To mode:'],
  ['fromProfileName', 'From profile name:'],
  ['toProfileName', 'To profile name:'],
  ['measurement', 'Measurement:'],
  ['controllerFilledValues', 'Controller filled values:'],
  ['trend', 'Trend:'],
  ['controlMode', 'Control mode:'],
  ['controllerFilledLocalDateTimes', 'Controller filled local date times:'],
  ['controllerFilledInstants', 'Controller filled instants:'],
  ['backfillTimestamp', 'Backfill timestamp:'],
  ['controllerId', 'Controller ID:'],
  ['isCalibration', 'Is calibration?'],
  ['basalComputedAmount', 'Basal computed amount:'],
  ['basalPassedSafetyNet', 'Basal passed safety net?'],
  ['basalSafetyNetAmount', 'Basal safety net amount:'],
  ['correctionBolusComputedAmount', 'Correction bolus computed amount:'],
  ['correctionBolusPassedSafetyNet', 'Correction bolus passed safety net?'],
  ['correctionBolusSafetyNetAmount', 'Correction bolus safety net amount:'],
  ['mealBolusComputedAmount', 'Meal bolus computed amount:'],
  ['mealBolusPassedSafetyNet', 'Meal bolus passed safety net?'],
  ['mealBolusSafetyNetAmount', 'Meal bolus safety net amount:'],
  ['manualBolusComputedAmount', 'Manual bolus computed amount:'],
  ['manualBolusPassedSafetyNet', 'Manual bolus passed safety net?'],
  ['manualBolusSafetyNetAmount', 'Manual bolus safety net amount:'],
  ['isStartingIOB', 'Is starting IOB?'],
  ['commandedValue', 'Commanded value:'],
  ['remainder', 'Remainder:'],
  ['injectedValue', 'Injected value:'],
  ['positiveDeviation', 'Positive deviation:'],
  ['pumpLogbookEntryId', 'Pump logbook entry ID:'],
  ['deliveryStatus', 'Delivery status:'],
  ['deliveryConfirmationUTCInstant', 'Delivery confirmation UTC instant:'],
  ['deliveryConfirmationLocalTime', 'Delivery confirmation local time:'],
  ['carbohydratesEstimate', 'Carbohydrates estimate:'],
  ['isHypoTreatment', 'Is hypo treatment?'],
  ['text', 'Text:'],
  ['noteInstant', 'Note instant:'],
  ['noteLocalDateTime', 'Note local date time:'],
  ['effectTimeInMin', 'Effect time in minutes:'],
  ['ratio', 'Ratio:'],
]);

export const DialogBoxEventType = (event: {
  eventType: any;
  message:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | null
    | undefined;
  exception:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | null
    | undefined;
  controllerId:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | null
    | undefined;
  fromMode:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  toMode:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  fromProfileName:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  toProfileName:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  measurement:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  intensity:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  durationInMinutes:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  startDateTime:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  amount:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | null
    | undefined;
  deliveryStatus:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | null
    | undefined;
  isPrandial:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  effectTimeInMinutes:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | null
    | undefined;
  standardBolusPercentage:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  carbohydrateEstimate:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  text:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
  ratio:
    | string
    | number
    | boolean
    | ReactElement<any, string | JSXElementConstructor<any>>
    | Iterable<ReactNode>
    | ReactPortal
    | null
    | undefined;
}): JSX.Element => {
  switch (event.eventType) {
    case eventTypes[0]:
      return (
        <>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Message: </Typography>
            <Typography variant="bodySmall">{event?.message}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Exception: </Typography>
            <Typography variant="bodySmall">{event?.exception}</Typography>
          </Box>
        </>
      );
    case eventTypes[1]:
      return (
        <>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Message: </Typography>
            <Typography variant="bodySmall">{event?.message}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Controller Id: </Typography>
            <Typography variant="bodySmall">{event?.controllerId}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">From mode: </Typography>
            <Typography variant="bodySmall">{event?.fromMode}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">To mode: </Typography>
            <Typography variant="bodySmall">{event?.toMode}</Typography>
          </Box>
        </>
      );
    case eventTypes[2]:
      return (
        <>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">From Profile Name: </Typography>
            <Typography variant="bodySmall">{event?.fromProfileName}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">To Profile Name: </Typography>
            <Typography variant="bodySmall">{event?.toProfileName}</Typography>
          </Box>
        </>
      );
    case eventTypes[3]:
      return (
        <>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Message: </Typography>
            <Typography variant="bodySmall">{event?.message}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Measurement: </Typography>
            <Typography variant="bodySmall">{event?.measurement}</Typography>
          </Box>
        </>
      );
    case eventTypes[4]:
      return (
        <>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Message: </Typography>
            <Typography variant="bodySmall">{event?.message}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Controller Id: </Typography>
            <Typography variant="bodySmall">{event?.controllerId}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Exception: </Typography>
            <Typography variant="bodySmall">{event?.exception}</Typography>
          </Box>
        </>
      );
    case eventTypes[5]:
      return (
        <>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Intensity: </Typography>
            <Typography variant="bodySmall">{event?.intensity}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Duration in minutes: </Typography>
            <Typography variant="bodySmall">{event?.durationInMinutes}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Start date time: </Typography>
            <Typography variant="bodySmall">{event?.startDateTime}</Typography>
          </Box>
        </>
      );
    case eventTypes[6]:
      return (
        <>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Amount: </Typography>
            <Typography variant="bodySmall">{event?.amount}</Typography>
          </Box>
        </>
      );
    case eventTypes[7]:
      return (
        <>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Amount: </Typography>
            <Typography variant="bodySmall">{event?.amount}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Delivery status: </Typography>
            <Typography variant="bodySmall">{event?.deliveryStatus}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Is prandial: </Typography>
            <Typography variant="bodySmall">{event?.isPrandial}</Typography>
          </Box>
        </>
      );
    case eventTypes[8]:
      return (
        <>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Amount: </Typography>
            <Typography variant="bodySmall">{event?.amount}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Delivery status: </Typography>
            <Typography variant="bodySmall">{event?.deliveryStatus}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Effect time in minutes: </Typography>
            <Typography variant="bodySmall">{event?.effectTimeInMinutes}</Typography>
          </Box>
        </>
      );
    case eventTypes[9]:
      return (
        <>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Amount: </Typography>
            <Typography variant="bodySmall">{event?.amount}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Effect time in minutes: </Typography>
            <Typography variant="bodySmall">{event?.effectTimeInMinutes}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Standard bolus percentage: </Typography>
            <Typography variant="bodySmall">{event?.standardBolusPercentage}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Delivery status: </Typography>
            <Typography variant="bodySmall">{event?.deliveryStatus}</Typography>
          </Box>
        </>
      );
    case eventTypes[10]:
      return (
        <Box sx={{display: 'flex', flexDirect: 'row'}}>
          <Typography variant="bodySmall">Message: </Typography>
          <Typography variant="bodySmall">{event?.message}</Typography>
        </Box>
      );
    case eventTypes[11]:
      return (
        <Box sx={{display: 'flex', flexDirect: 'row'}}>
          <Typography variant="bodySmall">Carbohydrate Estimate: </Typography>
          <Typography variant="bodySmall">{event?.carbohydrateEstimate}</Typography>
        </Box>
      );
    case eventTypes[12]:
      return (
        <Box sx={{display: 'flex', flexDirect: 'row'}}>
          <Typography variant="bodySmall">Text: </Typography>
          <Typography variant="bodySmall">{event?.text}</Typography>
        </Box>
      );
    case eventTypes[13]:
      return (
        <Box sx={{display: 'flex', flexDirect: 'row'}}>
          <Typography variant="bodySmall">Message: </Typography>
          <Typography variant="bodySmall">{event?.message}</Typography>
        </Box>
      );
    default:
      return (
        <>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Effect time in minutes: </Typography>
            <Typography variant="bodySmall">{event?.effectTimeInMinutes}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirect: 'row'}}>
            <Typography variant="bodySmall">Ratio: </Typography>
            <Typography variant="bodySmall">{event?.ratio}</Typography>
          </Box>
        </>
      );
  }
};
