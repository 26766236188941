import {useTheme, Typography, Box, Divider, Grid, Tooltip} from '@mui/material';
import {DeviceCard} from '../../../components';
import {GeneralSectionProps} from './types';
import {StyledContainer} from './styles';
import {GraphsWrapper} from '../../../sections/participant/general/GraphsWrapper';
import {mmolPerLToMgPerdL} from '../../../helpers';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import {pollingInterval} from '../../../constants/api';
import {measurementsService, participantsService} from '../../../api/services';
import {useCallback, useEffect, useRef, useMemo, useState} from 'react';
import {faUserXmark} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';
import {timeSampConvert} from './utils';

export const GeneralSection = ({generalRef, dynamicData, staticData}: GeneralSectionProps) => {
  const theme = useTheme();
  const {unit} = useAppSelector(unitSelector);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const {id, basalProfiles} = staticData;
  const {devices, lastMeal, lastMealBolus} = dynamicData;
  const profile = basalProfiles[0] ? basalProfiles[0]?.profile.map(value => value / 12) : []; // get array containing basal profile in U

  const [meal, setMeal] = useState<{
    timestamp: string | undefined;
    amount: number | undefined;
    value: number | undefined;
  }>({
    timestamp: lastMeal?.eventLocalDateTime,
    amount: lastMeal?.carbohydratesEstimate,
    value: lastMealBolus?.injectedValue || undefined,
  });
  const {
    data: latestCgmMeasurements,
    isUninitialized: isLatestCgmMeasurementsUninitialized,
    refetch: refetchLatestCgmMeasurements,
  } = measurementsService.useGetLatestCgmMeasurementsQuery(undefined, {
    pollingInterval,
  });

  const {
    data: dynamicDataLastHours,
    isUninitialized: isDynamicDataLastHoursUnitialized,
    refetch: refetchDynamicDataLastHours,
  } = participantsService.useGetDynamicScreenDataQuery({
    params: {id: `${id}?timeframe=3h`},
  });

  const refetchAllData = useCallback(() => {
    if (!isLatestCgmMeasurementsUninitialized) {
      refetchLatestCgmMeasurements();
    }
    if (!isDynamicDataLastHoursUnitialized) {
      refetchDynamicDataLastHours();
    }
  }, [
    isLatestCgmMeasurementsUninitialized,
    refetchLatestCgmMeasurements,
    isDynamicDataLastHoursUnitialized,
    refetchDynamicDataLastHours,
  ]);

  useEffect(() => {
    const handleInterval = () => {
      refetchAllData();
    };

    intervalRef.current = setInterval(handleInterval, 300000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [refetchAllData]);

  const latestCgmMeasurement =
    latestCgmMeasurements &&
    latestCgmMeasurements.find((measurement: {participantId: number}) => measurement.participantId === id);

  const cgmMeasurementsValue =
    latestCgmMeasurement?.measurement != null || (latestCgmMeasurement?.controlleFilledValues?.length ?? 0) > 0
      ? mmolPerLToMgPerdL(
          latestCgmMeasurement?.measurement != null
            ? latestCgmMeasurement.measurement
            : latestCgmMeasurement?.controlleFilledValues?.[latestCgmMeasurement.controlleFilledValues.length - 1] ?? 0,
          unit,
        )
      : 0;

  const latestLoopMode = latestCgmMeasurement?.loopMode ?? '--';

  // update insuline sum over last two hours
  const LastHoursInsulinTotal = useMemo(() => {
    if (!dynamicDataLastHours) return {total: null, earliestTimepoint: null};

    let total = 0;
    const currentTime = new Date(timeSampConvert(new Date().toISOString(), 'Europe/Zurich')); // TODO: make it dynamic (second release)
    const twoHoursAgoInMs = currentTime.getTime() - 2 * 60 * 60 * 1000;
    const twoHoursAgo = new Date(twoHoursAgoInMs);
    let earliestTimepoint = currentTime;
    const timeSlots = new Map(); // create map for time slots
    const slotsCount = 24; // define number of slots (every 5min in 2h)
    const slotDuration = 5 * 60 * 1000; // define slot length / 2

    // populate map with corresponding basal profile values
    for (let i = 0; i < slotsCount; i++) {
      const timeSlot = twoHoursAgoInMs + i * slotDuration; // increment time by 5min for each slot
      const staticIndex = Math.floor(new Date(timeSlot).getHours() % 24); // map to corresponding hour in basal profile
      timeSlots.set(timeSlot, profile[staticIndex] || 0);
    }

    // add boluses to total
    dynamicDataLastHours.boluses.forEach(item => {
      if (!item.eventLocalDateTime || item.deliveryStatus != 'DELIVERED' || item.injectedValue == null) return;

      const eventTime = new Date(item.eventLocalDateTime);
      if (
        eventTime >= twoHoursAgo &&
        item.basalSafetyNetAmount == null &&
        item.correctionBolusSafetyNetAmount == null
      ) {
        total += item?.injectedValue;
      }

      if (eventTime < earliestTimepoint) {
        earliestTimepoint = eventTime;
      }
    });

    // add microboluses/ OL basal profile to total
    dynamicDataLastHours.microboluses
      .slice()
      .reverse()
      .forEach(item => {
        if (!item.eventLocalDateTime || item.deliveryStatus != 'DELIVERED' || item.injectedValue == null) return;

        const eventTime = new Date(item.eventLocalDateTime);
        // check if event happened in last two hours and add to map
        if (
          eventTime > twoHoursAgo &&
          eventTime <= currentTime &&
          item.manualBolusSafetyNetAmount == null &&
          item.mealBolusPassedSafetyNet == null
        ) {
          let nextKey = null;

          // search for nearest time slot within the range
          timeSlots.forEach((value, key, map) => {
            const keysArray = Array.from(map.keys());
            const currentIndex = keysArray.indexOf(key);
            nextKey = currentIndex + 1 < keysArray.length ? keysArray[currentIndex + 1] : currentTime.getTime(); // find next key, representing time point 5 minutes later
            // find corresponding time slot to event
            if (nextKey != null && eventTime.getTime() >= key && eventTime.getTime() < nextKey) {
              timeSlots.set(key, item?.injectedValue);
            }
          });
        }
      });
    timeSlots.forEach((value, key) => {
      total += value; // add values from microboluses or static data
      if (key < earliestTimepoint.getTime()) {
        earliestTimepoint = new Date(key); // track earliest timepoint
      }
    });
    total = total || 0.001; // add tolerance to make zero value visible
    return {total, earliestTimepoint};
  }, [dynamicDataLastHours]);

  const {total, earliestTimepoint} = LastHoursInsulinTotal as {total: number; earliestTimepoint: Date};

  useEffect(() => {
    if (
      dynamicDataLastHours?.lastMeal &&
      dynamicDataLastHours?.lastMealBolus.deliveryStatus == 'DELIVERED' &&
      dynamicDataLastHours?.lastMealBolus.injectedValue == null
    ) {
      const {eventLocalDateTime, carbohydratesEstimate} = dynamicDataLastHours?.lastMeal;
      const injectedValue = dynamicDataLastHours?.lastMealBolus.injectedValue ?? 0;

      setMeal(prevMeal => {
        const newMeal = {
          timestamp: eventLocalDateTime || prevMeal?.timestamp,
          amount: carbohydratesEstimate || prevMeal?.amount,
          value: injectedValue ?? prevMeal?.value,
        };
        if (
          newMeal.timestamp !== prevMeal.timestamp ||
          newMeal.amount !== prevMeal.amount ||
          newMeal.value !== prevMeal.value
        ) {
          return newMeal; // only update if new meals is different
        }
        return prevMeal; // return old meal in case of no chanegs
      });
    }
  }, [dynamicDataLastHours]);

  return (
    <StyledContainer ref={generalRef}>
      <Box sx={{boxShadow: 1, borderRadius: 2, padding: 2, background: theme.palette.basic[0]}}>
        {staticData && (
          <Box sx={{display: 'flex', flexDirection: 'column', mb: '7px'}}>
            <Typography sx={{color: '#9099AA', fontSize: '13px'}}>#{staticData.id}</Typography>
            <Box sx={{display: 'flex', flexDirection: 'row'}}>
              <Typography sx={{color: '#2B2C2E', fontSize: '20px', fontWeight: '600', marginRight: '16px'}}>
                {staticData.name}
                <Tooltip title="Test Participant" arrow>
                  <FontAwesomeIcon
                    icon={faUserXmark as IconProp}
                    size="lg"
                    color="red"
                    style={{marginLeft: '8px', cursor: 'pointer'}}
                  />
                </Tooltip>
                {/* <Tooltip title="Real Participant" arrow>
                  <FontAwesomeIcon
                    icon={faUserCheck as IconProp}
                    size="lg"
                    color="#059984"
                    style={{marginLeft: '8px', cursor: 'pointer'}}
                  />
                </Tooltip> */}
              </Typography>
            </Box>
          </Box>
        )}
        <Divider />
        <Grid container xs={12} lg={12} xl={9} item spacing={2} mt={0.5} mb={1}>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <DeviceCard
              cardHeading="CGM and operation mode"
              showReservoirDetails={false}
              showBatteryPercentage={false}
              lastUpdatedTimeTitle="Last update:"
              lastUpdatedTime={latestCgmMeasurement?.eventLocalDateTime}
              showReadingValueAndUnit={true}
              readingValue={cgmMeasurementsValue}
              readingUnit={unit}
              readingTrend={latestCgmMeasurement?.trend}
              tooltipText="Glycemia read by the CGM sensor, current glucose trend (rate of change), and operation mode"
              isDeviceCard={true}
              readingMode={latestLoopMode}
              showLoopMode={true}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <DeviceCard
              cardHeading="Insulin pump"
              showReservoirDetails={true}
              reservoirPercentage={devices[1]?.pumpInsulinPercentage || NaN}
              showBatteryPercentage={true}
              batteryPercentage={devices[1]?.batteryPercentage || NaN}
              lastUpdatedTimeTitle="Insulin infused in the last 2h:"
              lastUpdatedTime={String(earliestTimepoint)}
              showLastHoursInsulin={true}
              readingValue={total}
              isDeviceCard={true}
              tooltipText="Sum of basal, meal boluses, and automatic/ manual correction boluses in the past two hours"
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
            <DeviceCard
              cardHeading="Last communicated meal"
              showReservoirDetails={false}
              lastUpdatedTimeTitle="Corresponding meal bolus "
              readingValue={meal.value}
              secondReadingValue={meal.amount}
              lastUpdatedTime={meal.timestamp}
              showLastCarboMeal={true}
              tooltipText="Carbohydrate content of the last communicated meal to the app by the user"
            />
          </Grid>
        </Grid>
        <GraphsWrapper {...basalProfiles} />
      </Box>
    </StyledContainer>
  );
};
