import {wssUrl} from '../../constants';
import type {EventResponse} from '../../api/service-types';

class WSService {
  private ws: WebSocket | null;
  private listeners: ((nextEvent: EventResponse) => void)[];

  constructor() {
    this.ws = null;
    this.listeners = [];
  }

  connect() {
    if (this.ws) {
      console.warn('WebSocket is already connected.');
      return;
    }

    this.ws = new WebSocket(`${wssUrl}/event-notifications/ws`);

    this.ws.addEventListener('open', () => {
      console.log('✅ WebSocket opened!');
    });

    this.ws.addEventListener('close', () => {
      console.log('❌ WebSocket closed!');
      this.ws = null;
    });

    this.ws.addEventListener('error', event => {
      console.error('⚠️ WebSocket erro:', event);
    });

    this.ws.addEventListener('message', event => {
      try {
        const nextEvent: EventResponse = JSON.parse(event.data);
        console.log('📩 New event received:', nextEvent);

        this.listeners.forEach(callback => callback(nextEvent));
      } catch (error) {
        console.error('❌ Error:', error);
      }
    });
  }

  disconnect() {
    if (!this.ws) return;
    this.ws.close();
    this.ws = null;
    this.listeners = [];
  }

  listener(callback: (nextEvent: EventResponse) => void) {
    if (!this.ws) {
      console.warn('⚠️ WebSocket not connected!');
      this.connect();
    }

    this.listeners.push(callback);
  }
}

export const wsService = new WSService();
