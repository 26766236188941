import React, {useMemo, useRef, useEffect, useState, useCallback} from 'react';
import {scaleLinear, scaleTime} from '@visx/scale';
import {AxisBottom, AxisLeft} from '@visx/axis';
import {Group} from '@visx/group';
import GridColumns from '@visx/grid/lib/grids/GridColumns';
import GridRows from '@visx/grid/lib/grids/GridRows';
import {mmolPerLToMgPerdL} from '../../../helpers';
import {useAppSelector} from '../../../store';
import {unitSelector} from '../../../store/reducers/unitSlice';
import moment from 'moment';
import styled from '@emotion/styled';
import {Grid, IconButton, Tooltip, Typography, useTheme} from '@mui/material';
import {DynamicData} from '../../../api/service-types';
import {Icon} from '../../../components';
import {Arc} from '@visx/shape';

interface DataPoint {
  x: string;
  y: number;
  isFilledValue: boolean;
}
interface BloodGlucoseChartProps {
  cgmMeasurements: DynamicData['cgmMeasurements'];
  crosshairTime: number | null;
  setCrosshairTime: (time: number | null) => void;
  selectedDateRange: string;
  minX: number;
  maxX: number;
}

export const BloodGlucoseChart = ({
  cgmMeasurements,
  crosshairTime,
  setCrosshairTime,
  selectedDateRange,
  minX,
  maxX,
}: BloodGlucoseChartProps): JSX.Element => {
  const svgRef = useRef<SVGSVGElement>(null);
  const [width, setWidth] = useState(1000);
  const [tooltipData, setTooltipData] = useState<DataPoint | null>(null);
  const [tooltipPosition, setTooltipPosition] = useState<{x: number; y: number} | null>(null);
  const theme = useTheme();
  const {unit} = useAppSelector(unitSelector);
  const convertToMmol = useCallback((value: number) => (unit === 'mmol/L' ? value / 18.018 : value), [unit]);

  const dataCombined = useMemo(
    () =>
      cgmMeasurements
        .filter(cgm => cgm.measurement != null || cgm.controllerFilledValues.length > 0)
        .filter(cgm => {
          const eventTime = new Date(cgm.eventLocalDateTime).getTime();
          return eventTime >= minX && eventTime <= maxX;
        })
        .map(cgm => ({
          x: cgm.eventLocalDateTime,
          y: mmolPerLToMgPerdL(
            cgm.measurement != null
              ? cgm.measurement
              : cgm.controllerFilledValues[cgm.controllerFilledValues.length - 1],
            unit,
          ),
          isFilledValue: cgm.measurement == null,
        })),
    [cgmMeasurements, unit, minX, maxX],
  );

  const minY = 0;
  const maxY = convertToMmol(400);
  const height = 250;
  const margin = {top: 20, right: 20, bottom: 30, left: 40};

  const xScale = useMemo(
    () =>
      scaleTime({
        domain: [minX, maxX],
        range: [margin.left, width - margin.right],
      }),
    [minX, maxX, width, margin.left, margin.right],
  );

  const yScale = useMemo(
    () =>
      scaleLinear({
        domain: [minY, maxY],
        range: [height - margin.bottom, margin.top],
      }),
    [minY, maxY, height, margin.bottom, margin.top],
  );

  // adjust tick number based on screen width
  const numTicks = useMemo(() => {
    return Math.floor(width / 75);
  }, [width]);

  const ranges = useMemo(
    () => [
      {min: 0, max: convertToMmol(54), color: '#940106'},
      {min: convertToMmol(54), max: convertToMmol(70), color: '#FF1C01'},
      {min: convertToMmol(70), max: convertToMmol(180), color: '#47D120'},
      {min: convertToMmol(180), max: convertToMmol(250), color: '#FADA28'},
      {min: convertToMmol(250), max: convertToMmol(400), color: '#FF9D00'},
    ],
    [convertToMmol],
  );

  useEffect(() => {
    if (svgRef.current) {
      setWidth(svgRef.current.clientWidth);
    }

    const handleResize = () => {
      if (svgRef.current) {
        setWidth(svgRef.current.clientWidth);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseMove = useCallback(
    (event: React.MouseEvent<SVGRectElement, MouseEvent>) => {
      const {clientX} = event;
      const svgRect = svgRef.current?.getBoundingClientRect();
      if (!svgRect) return;

      const mouseXPosition = clientX - svgRect.left;

      const closestDatum =
        dataCombined.length > 0
          ? dataCombined.reduce((prev, curr) => {
              const prevDistance = Math.abs(xScale(new Date(prev.x)) - mouseXPosition);
              const currDistance = Math.abs(xScale(new Date(curr.x)) - mouseXPosition);
              return currDistance < prevDistance ? curr : prev;
            })
          : null;

      const timeAtMousePosition = xScale.invert(mouseXPosition).getTime();
      setCrosshairTime(timeAtMousePosition);

      if (!closestDatum) {
        setTooltipData(null);
        setTooltipPosition(null);
        return;
      }
      const tooltipXPos = Math.min(Math.max(mouseXPosition + 56, margin.left), width - margin.right - 50);
      const tooltipYPos = Math.min(Math.max(yScale(closestDatum.y) - 25, margin.top), height - margin.bottom - 1);
      setTooltipData(closestDatum);
      setTooltipPosition({
        x: tooltipXPos,
        y: tooltipYPos,
      });
    },
    [dataCombined, xScale, yScale, setCrosshairTime],
  );

  const handleMouseLeave = useCallback(() => {
    setTooltipData(null);
    setTooltipPosition(null);
    setCrosshairTime(null);
  }, [setCrosshairTime]);

  const renderCircles = useCallback(
    (data: DataPoint[]) =>
      data.map((d, i) => {
        const isHovered = tooltipData === d;
        const hoveredSize = isHovered ? 3 : 2;
        const circleSize = selectedDateRange === '1h' || selectedDateRange === '3h' ? 3 : hoveredSize;

        const desiredX = xScale(new Date(d.x));
        const desiredY = yScale(d.y);

        if (d.isFilledValue) {
          return (
            <g
              key={`arc-${i}`}
              transform={`translate(${desiredX}, ${desiredY})`}
              onMouseMove={() => {
                setTooltipData(d);
                setTooltipPosition({x: desiredX, y: desiredY});
              }}
              onMouseLeave={() => {
                if (tooltipData === d) {
                  setTooltipData(null);
                  setTooltipPosition(null);
                }
              }}>
              <Arc
                innerRadius={circleSize}
                outerRadius={circleSize}
                startAngle={0}
                endAngle={2 * Math.PI}
                fill="none"
                stroke={
                  isHovered ? 'black' : ranges.find(range => d.y >= range.min && d.y < range.max)?.color || 'gray'
                }
              />
            </g>
          );
        } else {
          return (
            <circle
              key={`circle-${i}`}
              cx={desiredX}
              cy={desiredY}
              r={circleSize}
              fill={isHovered ? 'black' : ranges.find(range => d.y >= range.min && d.y < range.max)?.color || 'gray'}
              onMouseMove={() => {
                setTooltipData(d);
                setTooltipPosition({
                  x: desiredX,
                  y: desiredY,
                });
              }}
              onMouseLeave={() => {
                if (tooltipData === d) {
                  setTooltipData(null);
                  setTooltipPosition(null);
                }
              }}
            />
          );
        }
      }),
    [ranges, xScale, yScale, tooltipData, selectedDateRange],
  );

  const crosshairXPosition = useMemo(
    () => (crosshairTime ? xScale(new Date(crosshairTime)) : null),
    [crosshairTime, xScale],
  );

  const ChartLabel = styled('h5')(() => ({
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    padding: 0,
    marginLeft: '30px',
    marginTop: '6rem',
  }));

  return (
    <>
      <Grid container xs={12} spacing={2} mt={2} marginTop={'15px'}>
        <Grid xs={0.5} position={'relative'} display={'flex'} padding={0} justifyContent="flex-end" marginLeft="auto">
          <ChartLabel
            style={{
              position: 'absolute',
              writingMode: 'vertical-lr',
              transform: 'rotate(180deg)',
              marginLeft: margin.bottom,
            }}>
            {unit}
          </ChartLabel>
          <Tooltip
            title={
              <React.Fragment>
                <Typography variant="h6" gutterBottom>
                  Glucose
                </Typography>
                <Typography variant="body2">
                  Continuous glucose monitoring (CGM) data over time displayed in the selected measurement unit.
                </Typography>
              </React.Fragment>
            }
            arrow>
            <div style={{position: 'relative', float: 'right', height: '15px'}}>
              <IconButton>
                <Icon icon="info" size={16} color={theme.palette.basic[500]} />
              </IconButton>
            </div>
          </Tooltip>
        </Grid>
        <Grid xs={11.5}>
          <div style={{position: 'relative'}}>
            <svg ref={svgRef} width="100%" height={height}>
              <Group>
                {/* Green background */}
                <rect
                  x={margin.left}
                  y={yScale(convertToMmol(180))}
                  width={width - margin.left - margin.right}
                  height={yScale(convertToMmol(70)) - yScale(convertToMmol(180))}
                  fill="rgba(0, 128, 0, 0.08)"
                />
                <line
                  x1={margin.left}
                  x2={width - margin.right}
                  y1={yScale(convertToMmol(180))}
                  y2={yScale(convertToMmol(180))}
                  stroke="rgba(0, 128, 0, 0.15)"
                  strokeWidth={2}
                />
                <line
                  x1={margin.left}
                  x2={width - margin.right}
                  y1={yScale(convertToMmol(120))}
                  y2={yScale(convertToMmol(120))}
                  stroke="rgba(0, 128, 0, 0.15)"
                  strokeWidth={1}
                />
                <line
                  x1={margin.left}
                  x2={width - margin.right}
                  y1={yScale(convertToMmol(70))}
                  y2={yScale(convertToMmol(70))}
                  stroke="rgba(0, 128, 0, 0.15)"
                  strokeWidth={2}
                />

                {/* Grid */}
                <GridColumns
                  scale={xScale}
                  top={margin.top}
                  height={height - margin.bottom - margin.top}
                  strokeDasharray="5,5"
                  pointerEvents="none"
                />
                <GridRows
                  scale={yScale}
                  width={width - margin.right - margin.left}
                  strokeDasharray="2,2"
                  left={margin.left}
                />

                {/* Axes */}
                <AxisBottom
                  numTicks={numTicks}
                  top={height - margin.bottom}
                  scale={xScale}
                  tickLabelProps={() => ({
                    fill: 'black',
                    fontSize: 11,
                    textAnchor: 'middle',
                  })}
                  tickFormat={d => {
                    const date = d instanceof Date ? d : new Date(d.valueOf());
                    return date.toLocaleTimeString('en-EU', {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    });
                  }}
                />
                <AxisLeft
                  scale={yScale}
                  left={margin.left}
                  tickLabelProps={() => ({
                    fill: 'black',
                    fontSize: 11,
                    textAnchor: 'end',
                  })}
                />

                {/* Crosshair */}
                {crosshairXPosition !== null && (
                  <line
                    x1={crosshairXPosition}
                    x2={crosshairXPosition}
                    y1={margin.top}
                    y2={height - margin.bottom}
                    stroke="gray"
                    strokeDasharray="4,4"
                  />
                )}

                {/* Scatter points */}
                {renderCircles(dataCombined)}

                {/* Capture mouse events */}
                <rect
                  x={margin.left}
                  y={margin.top}
                  width={width - margin.left - margin.right}
                  height={height - margin.bottom}
                  fill="transparent"
                  onMouseMove={handleMouseMove}
                  onMouseLeave={handleMouseLeave}
                />
              </Group>
            </svg>

            {tooltipData && tooltipPosition && (
              <div
                style={{
                  position: 'absolute',
                  left: `${tooltipPosition.x}px`,
                  top: `${tooltipPosition.y}px`,
                  backgroundColor: 'white',
                  padding: '5px 10px',
                  pointerEvents: 'none',
                  transform: 'translate(-50%, -100%)',
                  boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)',
                  borderRadius: '4px',
                  zIndex: '99',
                  minWidth: '111px',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textAlign: 'center',
                }}>
                <h5 style={{margin: 0, padding: 0, color: theme.palette.customColors?.lightGrey || 'black'}}>
                  Glucose level
                </h5>
                <p style={{fontWeight: 'bold', fontSize: '14px', margin: 0, padding: 0}}>
                  {tooltipData.y + ' ' + unit}
                </p>
                <p
                  style={{
                    fontSize: '12px',
                    margin: 0,
                    padding: 0,
                  }}>
                  {moment(tooltipData.x).format('DD/MM/YY HH:mm')}
                </p>
              </div>
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
};
