import {useState, useEffect} from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import {Box, SelectChangeEvent, Typography, useTheme, Tooltip} from '@mui/material';
import {AssignUser, BasicSelect, EventComments, SnackbarAlert, Icon, IconButton} from '../../components';
import {priorityOptions} from '../../constants/selectionOptions';
import {addSpaceBeforeUppercaseFn} from '../../helpers/commonReusableFns';
import {StatusFilter, eventsService} from '../../api';
import {usePopover} from '../../hooks';
import {Severity} from '../event-comments/types';
import {changeFormateDate, changeFormateDateUTC} from '../../utils';
import {StatusComponent} from '../status/Status';
import {eventParameterMap} from './DialogBoxEventType';

export interface DialogBoxProps {
  dialogOpen: boolean;
  onClose: () => void;
  onRefetch: () => void;
  selectedEvent?: any;
  setSelectedEvent: React.Dispatch<React.SetStateAction<string>>;
  uniqueStatuses: StatusFilter[];
}

export const DialogBox = (props: DialogBoxProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const {onClose, onRefetch, dialogOpen, selectedEvent, uniqueStatuses, setSelectedEvent} = props;
  const {handleClose} = usePopover<HTMLButtonElement>();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [severity, setSeverity] = useState<Severity>('success');
  const [message, setMessage] = useState<string>('');
  const [selectedPriority, setSelectedPriority] = useState<string>(selectedEvent?.event?.priority);

  const [updateEvent] = eventsService.useUpdateEventMutation();

  const eventTypeWholeString = selectedEvent?.originatingEvent.type;
  const eventTypeParts = eventTypeWholeString?.split('.');
  const eventTypeUnformatted = eventTypeParts && eventTypeParts[eventTypeParts?.length - 1];
  const eventType = eventTypeUnformatted && addSpaceBeforeUppercaseFn(eventTypeUnformatted);
  const eventPriority = selectedEvent?.priority;

  const handleSnackbarClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  const handlePriorityChange = async (e: SelectChangeEvent) => {
    const priorityValue = Number(e?.target?.value);

    if (!selectedEvent?.id) {
      setMessage('Invalid event selected!');
      setSeverity('warning');
      setOpenSnackbar(true);
      return;
    }

    try {
      await updateEvent({
        body: {
          id: selectedEvent.id,
          priority: priorityValue,
        },
      });

      setSelectedEvent(() => ({
        ...selectedEvent,
        priority: priorityValue,
      }));
      setSelectedPriority(e?.target?.value);
      setMessage('Event priority updated successfully!');
      setSeverity('success');
    } catch (error) {
      console.error('Error while updating event priority:', error);

      setMessage('Error while updating event priority!');
      setSeverity('error');
    } finally {
      setOpenSnackbar(true);
      handleClose();
    }
  };

  const defaultPriorityValue = eventPriority?.toString();

  useEffect(() => {
    setSelectedPriority(defaultPriorityValue);
  }, [defaultPriorityValue, onClose]);

  // helper function to fetch nested values from selectedEvent based on key
  const getParameterValue = (path: string) => {
    return path.split('.').reduce((acc, key) => acc?.[key], selectedEvent?.originatingEvent);
  };

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        onClose={() => {
          onClose();
          onRefetch();
        }}
        open={dialogOpen}>
        <StatusComponent
          onClose={onClose}
          selectedEvent={selectedEvent}
          setSelectedEvent={setSelectedEvent}
          uniqueStatuses={uniqueStatuses}
          onRefetch={onRefetch}
        />
        <DialogContent dividers>
          <Tooltip title={<div style={{whiteSpace: 'pre-wrap'}}>{JSON.stringify(selectedEvent, null, 2)}</div>} arrow>
            <div style={{position: 'relative', float: 'right', height: '15px'}}>
              <IconButton>
                <Icon icon="info" size={16} color={theme.palette.basic[500]} />
              </IconButton>
            </div>
          </Tooltip>
          <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}}>
            <Typography variant="bodySmall" sx={{width: '10rem'}}>
              Event ID:
            </Typography>
            <Typography variant="bodySmall">{selectedEvent?.id}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}}>
            <Typography variant="bodySmall" sx={{width: '10rem'}}>
              Priority:
            </Typography>
            <BasicSelect
              background={selectedPriority === '3' ? theme.palette.errorCustom[800] : theme.palette.basic[300]}
              color={theme.palette.basic[0]}
              colorModeSelected="white"
              label="Priority"
              defaultValue={eventPriority?.toString()}
              value={selectedPriority}
              menuItems={priorityOptions}
              handleChange={handlePriorityChange}
            />
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}}>
            <Typography variant="bodySmall" sx={{width: '10rem'}}>
              Type:
            </Typography>
            <Typography variant="bodySmall">{eventType}</Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}}>
            <Typography variant="bodySmall" sx={{width: '10rem'}}>
              Event description:
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="bodySmall">{selectedEvent?.description}</Typography>
            </Box>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}}>
            <Typography variant="bodySmall" sx={{width: '10rem'}}>
              Operation mode:
            </Typography>
            <Box sx={{display: 'flex', flexDirection: 'column'}}>
              <Typography variant="bodySmall">{selectedEvent?.originatingEvent.loopMode}</Typography>
            </Box>
          </Box>
          {/* Dynamically render the event details */}
          {Array.from(eventParameterMap).map(([paramKey, title]) => {
            const value = getParameterValue(paramKey);
            // skip rendering if value is empty or null
            if (value == null || (Array.isArray(value) && value.length === 0) || value === '') {
              return null;
            }
            const validValue = typeof value === 'object' && !Array.isArray(value) ? value?.id : value;
            return (
              <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}} key={paramKey}>
                <Typography variant="bodySmall" sx={{width: '10rem'}}>
                  {title}
                </Typography>
                <Typography variant="bodySmall">{validValue}</Typography>
              </Box>
            );
          })}
          <Box sx={{display: 'flex', flexDirection: 'row', mb: '20px'}}>
            <Typography variant="bodySmall" sx={{width: '10rem'}}>
              Assign to:
            </Typography>
            <AssignUser
              event={selectedEvent}
              setSelectedEvent={setSelectedEvent}
              onRefetch={onRefetch}
              onClose={onClose}
            />
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Typography variant="bodySmall" sx={{width: '150px'}}>
              Last update from website user:
            </Typography>
            <Typography variant="bodySmall">
              {selectedEvent?.originatingEvent.eventUTCInstant
                ? changeFormateDateUTC(selectedEvent?.originatingEvent.eventUTCInstant)
                : 'No event date available!'}
            </Typography>
          </Box>
          <Box sx={{display: 'flex', flexDirection: 'row'}}>
            <Typography variant="bodySmall" sx={{width: '150px'}}>
              Last update from local participant:
            </Typography>
            <Typography variant="bodySmall">
              {selectedEvent?.originatingEvent.eventLocalDateTime
                ? changeFormateDate(selectedEvent?.originatingEvent.eventLocalDateTime)
                : 'No event date available!'}
            </Typography>
          </Box>
          {selectedEvent && (
            <EventComments event={selectedEvent} setSelectedEvent={setSelectedEvent} onRefetch={onRefetch} />
          )}
        </DialogContent>
      </Dialog>
      <SnackbarAlert open={openSnackbar} onClose={handleSnackbarClose} message={message} severity={severity} />
    </>
  );
};
