import {Box, Typography, useTheme} from '@mui/material';
import {useState} from 'react';
import dayjs from 'dayjs';
import {Link, useParams} from 'react-router-dom';

import {EventCard, Icon, MultiSelect, Scrollbar, CircularProgress} from '../../../components';
import {pollingInterval} from '../../../constants';
import {participantsService, eventsService} from '../../../api';
import type {Participant} from '../../../api/service-types';

import {StyledContainer, StyledContent, StyledHeader} from './styles';
import {NoEventsYet} from '../Utils';

export const Events = (): JSX.Element => {
  return (
    <StyledContainer>
      <Scrollbar
        sx={{
          height: 1,
          '& .simplebar-content': {
            height: 1,
            display: 'flex',
            flexDirection: 'column',
          },
        }}>
        <Content />
      </Scrollbar>
    </StyledContainer>
  );
};

const Content = (): JSX.Element => {
  const theme = useTheme();
  const {id} = useParams();
  const [selectedParticipants, setSelectedParticipants] = useState<Participant[]>([]);

  if (!id) throw new Error('clinical trial id is undefined, unhandled error');

  const {
    data: participantsByClinicalTrialId,
    isLoading: areParticipantsByClinicalTrialIdLoading,
    error: participantsByClinicalTrialIdError,
    refetch: refetchParticipantsByClinicalTrialId,
  } = participantsService.useGetParticipantsByClinicalTrialIdQuery({params: {id}});

  const {
    data: events,
    isLoading: areEventsLoading,
    error: eventsError,
    refetch: refetchEvents,
  } = eventsService.useGetEventsByClinicalTrialIdQuery({params: {id}}, {pollingInterval});

  const onRefetch = async () => {
    refetchParticipantsByClinicalTrialId();
    refetchEvents();
  };

  if (areParticipantsByClinicalTrialIdLoading || areEventsLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="100%">
        <CircularProgress size={40} />
      </Box>
    );
  }

  if (participantsByClinicalTrialIdError || eventsError) {
    return (
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" width="100%" height="100%">
        <Typography variant="h6" color="error">
          Error
        </Typography>
        <button onClick={onRefetch}>Reload data</button>
      </Box>
    );
  }

  if (!participantsByClinicalTrialId || !events) {
    throw new Error('participantsByClinicalTrialId or events are undefined, unhandled state');
  }

  const eventsByClinicalTrialParticipants = events.data.filter(event =>
    participantsByClinicalTrialId.some(participant => event.participantId === participant.id),
  );

  const selectParticipants = (nextSelectedParticipants: Participant[]) => {
    setSelectedParticipants(nextSelectedParticipants);
  };

  const hasSelectedParticipants = selectedParticipants.length > 0;

  const eventsSorted = [...eventsByClinicalTrialParticipants].sort((a, b) => {
    return dayjs(b.notificationUTCInstant).diff(dayjs(a.notificationUTCInstant));
  });

  const eventsFiltered = eventsSorted.filter(event =>
    hasSelectedParticipants
      ? selectedParticipants.some(participant => event.participantId === participant.id) && event.priority === 3
      : event.priority === 3,
  );

  return (
    <StyledContent>
      <StyledHeader>
        <Link to="/dashboard/events">
          <Typography variant="linkHeading">Event</Typography>
        </Link>
        <Icon icon="right" color={theme.palette.basic[600]} size="16px" />
        <Box flex={1} />
        <MultiSelect
          value={selectedParticipants}
          options={participantsByClinicalTrialId}
          onChange={selectParticipants}
          placeholder="Participant"
          width={'142px'}
          searchKeys={['name']}
          keyExtractor={(option: Participant) => String(option.id)}
          renderOption={(option: Participant, isSelected) => (
            <Typography
              variant={isSelected ? 'bodyBigSemibold' : 'bodyBig'}
              color={isSelected ? theme.palette.primaryCustom[1000] : theme.palette.basic[800]}>
              {option.name}
            </Typography>
          )}
        />
      </StyledHeader>
      <Box>
        {eventsFiltered.length > 0 ? (
          eventsFiltered.map(event => <EventCard key={`${event.id}`} event={event} />)
        ) : (
          <NoEventsYet />
        )}
      </Box>
    </StyledContent>
  );
};
