import {Typography, useTheme} from '@mui/material';

import {getFullDateTime} from '../../../helpers';
import type {Participant, EventResponse} from '../../../api/service-types';

import {
  StyledCard,
  StyledCardContent,
  StyledEventInfo,
  StyledInfo,
  StyledStatusCircle,
  StyledStatusLine,
} from './styles';

export type EventCardLayoutProps = {
  event: EventResponse;
  participant: Participant;
  withEventDateTime?: boolean;
};

// type EventTypeOption = {
//   title: string;
//   assign: boolean;
//   comment: boolean;
// };

// const eventTypeMap: Record<EventType, EventTypeOption> = {
//   'com.unibe.precisionlab.models.events.AppEvent': {
//     title: 'App event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.ApplicationControlModeChangeEvent': {
//     title: 'Application control mode change event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.BasalProfileChangeEvent': {
//     title: 'Basal profile change event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.CGMEvent': {
//     title: 'CGM event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.CGMMeasurement': {
//     title: 'CGM measurement',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.ControllerEvent': {
//     title: 'Controller event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.Event': {
//     title: 'Event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.EventComments': {
//     title: 'Event comments',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.ExerciseEvent': {
//     title: 'Exercise event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.GlucometerMeasurement': {
//     title: 'Exercise event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.InsulinBasalEvent': {
//     title: 'Insulin basal event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.InsulinBolusEvent': {
//     title: 'Insulin bolus event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.InsulinComputedEvent': {
//     title: 'Insulin computed event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.InsulinExtras': {
//     title: 'Insulin extras event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.InsulinPumpEvent': {
//     title: 'Insulin pump event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.KetonesMeasurement': {
//     title: 'Ketones measurement',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.MealEvent': {
//     title: 'Meal event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.NoteEvent': {
//     title: 'Note event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.PhoneEvent': {
//     title: 'Phone event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.TemporalBasalChangeEvent': {
//     title: 'Temporal basal change event',
//     assign: true,
//     comment: true,
//   },

//   // Check if its needed - all next
//   'com.unibe.precisionlab.models.events.ControllerStateChangeEvent': {
//     title: 'Controller state change event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.InsulinStandardBolus': {
//     title: 'Insulin Standard bolus event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.InsulinExtendedBolus': {
//     title: 'Insulin Extended bolus event',
//     assign: true,
//     comment: true,
//   },
//   'com.unibe.precisionlab.models.events.InsulinDualBolus': {
//     title: 'Insulin Dual bolus event',
//     assign: true,
//     comment: true,
//   },
// };

export const EventCardLayout = ({event, participant, withEventDateTime = true}: EventCardLayoutProps): JSX.Element => {
  const theme = useTheme();

  const eventDateTime = getFullDateTime(event.notificationUTCInstant);

  return (
    <StyledCard>
      {event.priority && <StyledStatusLine priority={event.priority} />}
      <StyledCardContent>
        <StyledEventInfo>
          <StyledInfo>
            <Typography variant="bodyBigSemibold" display="block" position="relative">
              {event.priority && <StyledStatusCircle priority={event.priority} />}
              {participant.name}
            </Typography>
            {withEventDateTime && (
              <Typography variant="bodyExtraSmall" color={theme.palette.basic[600]}>
                {eventDateTime}
              </Typography>
            )}
          </StyledInfo>
          {/* <Typography variant="bodySmall" color={theme.palette.basic[800]}>
            {`${eventTypeMap[event.originatingEvent.type]?.title} #${event.id}`}
          </Typography> */}
        </StyledEventInfo>
        {event.description && <Typography variant="bodyBig">{event.description}</Typography>}
        {/* <StyledActions>
          {eventTypeMap[event.originatingEvent.type]?.comment && <Comment event={event} />}
          {eventTypeMap[event.originatingEvent.type]?.assign && <Assign event={event} />}
        </StyledActions> */}
      </StyledCardContent>
    </StyledCard>
  );
};
