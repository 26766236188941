import {useTheme, Typography} from '@mui/material';

import {SwitchUnit} from '../../components';
import {Profile} from '../../sections';
import {StyledContainer, StyledUnitsContainer, StyledSwitchContainer} from './styles';

export const SettingsPage = () => {
  const theme = useTheme();

  return (
    <StyledContainer>
      <Typography variant="h2" color={theme.palette.basic[1000]}>
        Settings
      </Typography>
      <Profile />
      <StyledUnitsContainer>
        <Typography variant="h4" color={theme.palette.basic[1000]}>
          Measurement units
        </Typography>
        <StyledSwitchContainer>
          <Typography variant="bodyBig" color={theme.palette.basic[1000]}>
            Glucose
          </Typography>
          <SwitchUnit />
        </StyledSwitchContainer>
      </StyledUnitsContainer>
    </StyledContainer>
  );
};
