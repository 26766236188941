import {Outlet} from 'react-router-dom';
import {Box, Typography} from '@mui/material';

import {Logo} from '../../components';
import {useResponsive} from '../../hooks';
import {StyledCardMedia, StyledRoot, StyledSection} from './styles';

export const AuthLayout = () => {
  const mdUp = useResponsive({query: 'up', start: 'md'});

  return (
    <StyledRoot>
      <Box
        sx={{
          position: 'fixed',
          top: {xs: 16, sm: 24, md: 40},
          left: {xs: 16, sm: 24, md: 40},
        }}>
        <Logo />
      </Box>

      {mdUp && (
        <StyledSection>
          <StyledCardMedia image={require('../../assets/images/login-background.png')}>
            <Typography variant="h0" sx={{px: 5, mt: 10, mb: 5}}>
              Providing 24/7 monitoring to our clinical trial participants
            </Typography>
          </StyledCardMedia>
        </StyledSection>
      )}

      <Outlet />
    </StyledRoot>
  );
};
