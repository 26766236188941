import {ENDPOINT_TIME_OPTIONS} from '../../../constants';
import {useCallback, useEffect, useRef, useState} from 'react';
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, CircularProgress} from '@mui/material';
import {BloodGlucoseChart, InsulinAndMealsChart} from '../../../components';
import {BasalProfile, participantsService} from '../../../api';
import {useParams} from 'react-router-dom';
import {BolusAndTreatmentChart} from '../../../components/charts/bolus-and-treatment/BolusAndTreatment';
import {getTimeDomain} from './utils';

export const GraphsWrapper = (basalProfiles: BasalProfile[]) => {
  const {id} = useParams();
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [crosshairTime, setCrosshairTime] = useState<number | null>(null);
  const [selectedDateRange, setSelectedDateRange] = useState<string>(
    sessionStorage.getItem('sessionTimeFrame') || ENDPOINT_TIME_OPTIONS.THREE_HOURS,
  );
  const [param, setParam] = useState<string>(`${id}?timeframe=${selectedDateRange}`);
  const initialTimeDomain = getTimeDomain(selectedDateRange, new Date().toISOString());
  const [timeDomain, setTimeDomain] = useState<{minX: number; maxX: number}>({
    minX: initialTimeDomain[0],
    maxX: initialTimeDomain[1],
  });

  const {
    data: graphsData,
    isLoading: isGraphsDataLoading,
    isFetching: isGraphsFetching,
    refetch: refetchGraphsData,
    isUninitialized,
  } = participantsService.useGetDynamicScreenDataQuery({
    params: {id: param},
  });

  useEffect(() => {
    setParam(`${id}?timeframe=${selectedDateRange}`);
  }, [id, selectedDateRange]);

  useEffect(() => {
    if (!isUninitialized) {
      refetchGraphsData().then(response => {
        console.log('Fetched Data:', response); //veriy if data is updated
        const [newMinX, newMaxX] = getTimeDomain(selectedDateRange, new Date().toISOString()); // update time domain when new data available
        setTimeDomain({minX: newMinX, maxX: newMaxX});
      });
    }
  }, [param, isUninitialized, refetchGraphsData]);

  const refetchTeam = useCallback(() => {
    if (!isUninitialized) {
      refetchGraphsData().then(response => {
        console.log('Auto-refetch Data:', response); // verify refetch
        const [newMinX, newMaxX] = getTimeDomain(selectedDateRange, new Date().toISOString()); // update time domain on call
        setTimeDomain({minX: newMinX, maxX: newMaxX});
      });
    }
  }, [isUninitialized, refetchGraphsData, selectedDateRange]);

  // set automatic update interval
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      refetchTeam();
    }, 300000); // 5 min = 300000

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [refetchTeam]);

  // TimeFrame selector
  const handleChange = useCallback((event: SelectChangeEvent) => {
    const newTimeFrame = event?.target?.value as string;
    sessionStorage.setItem('sessionTimeFrame', newTimeFrame);
    setSelectedDateRange(newTimeFrame);
    // update minX and maxX if the `selectedDateRange` changes
    const [newMinX, newMaxX] = getTimeDomain(selectedDateRange, new Date().toISOString());
    setTimeDomain({minX: newMinX, maxX: newMaxX});
  }, []);

  if (isGraphsDataLoading) {
    return (
      <div style={{width: '100%', height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
        <CircularProgress size={40} color="success" />
      </div>
    );
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}}>
      <Box sx={{width: 300, marginTop: 5, display: 'flex', alignItems: 'center'}}>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Time frame</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectedDateRange}
            label="Time frame"
            onChange={handleChange}>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.HOUR}>1 Hour</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_HOURS}>3 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.SIX_HOURS}>6 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.EIGHT_HOURS}>8 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.TEN_HOURS}>10 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.TWELVE_HOURS}>12 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.SIXTEEN_HOURS}>16 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.DAY}>24 Hours</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.THREE_DAYS}>3 Days</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.WEEK}>1 Week</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.TWO_WEEKS}>2 Weeks</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.MONTH}>1 Month</MenuItem>
            <MenuItem value={ENDPOINT_TIME_OPTIONS.ALL_TIME}>3 Months</MenuItem>
          </Select>
        </FormControl>
        <div style={{width: '2rem', marginLeft: 5}}>
          {isGraphsFetching && <CircularProgress size={20} sx={{marginLeft: 1}} color={'success'} />}
        </div>
      </Box>
      {graphsData && (
        <div>
          <BloodGlucoseChart
            {...graphsData}
            crosshairTime={crosshairTime}
            setCrosshairTime={setCrosshairTime}
            selectedDateRange={selectedDateRange}
            minX={timeDomain.minX}
            maxX={timeDomain.maxX}
          />
          <InsulinAndMealsChart
            {...graphsData}
            crosshairTime={crosshairTime}
            setCrosshairTime={setCrosshairTime}
            basalProfiles={basalProfiles}
            selectedDateRange={selectedDateRange}
            minX={timeDomain.minX}
            maxX={timeDomain.maxX}
          />
          <BolusAndTreatmentChart
            {...graphsData}
            crosshairTime={crosshairTime}
            setCrosshairTime={setCrosshairTime}
            selectedDateRange={selectedDateRange}
            minX={timeDomain.minX}
            maxX={timeDomain.maxX}
          />
        </div>
      )}
    </div>
  );
};
